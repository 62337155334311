import { COLOURS } from '@core-ui/styles';
import { CSSProperties } from 'react';

export default (): Record<string, CSSProperties> => ({
  container: {
    height: '100%',
    backgroundColor: COLOURS.HUE_COCONUT.HUE_16,
  },
  text: {
    wordBreak: 'break-all',
  },
});
