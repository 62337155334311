import { extractFlattenPaths, Route } from '@core-ui/redux-router';
import analyticsRoutes from 'src/pages/analytics/routes';
import backofficeRoutes from 'src/pages/backoffice/routes';
import chatsRoutes from 'src/pages/chats/routes';
import documentsRoutes from 'src/pages/documents/routes';
import FAQRoutes from 'src/pages/faq/routes';
import financesRoutes from 'src/pages/finances/routes';
import loginRoutes from 'src/pages/login/routes';
import mobileRoutes from 'src/pages/mobile/routes';
import Page404Routes from 'src/pages/page404/routes';
import rootRoutes from 'src/pages/root/routes';
import settingsRoutes from 'src/pages/settings/routes';

const routes: Route[] = [
  ...rootRoutes,
  ...mobileRoutes,
  ...loginRoutes,
  ...backofficeRoutes,
  ...financesRoutes,
  ...analyticsRoutes,
  ...chatsRoutes,
  ...documentsRoutes,
  ...settingsRoutes,
  ...FAQRoutes,
  ...Page404Routes,
];

export const APPLICATION_PATHS = extractFlattenPaths(routes);

export default routes;
