import State from 'src/app/types/state';
import { createSelector } from 'reselect';

const getLoading = (state: State) => state.login.form.loading;
const getShowPassword = (state: State) => state.login.form.showPassword;
const getLoginErrors = (state: State) => state.login.form.hasLoginErrors;

export default createSelector(
  [getLoading, getShowPassword, getLoginErrors],
  (loading, showPassword, hasLoginErrors) => ({
    loading,
    showPassword,
    hasLoginErrors,
  })
);
