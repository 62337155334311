import { useQuery } from '@core-ui/redux-router';
import Tabs, { ITab } from '@core-ui/tabs';
import { buildURL, updateQuery } from '@core-ui/url';
import Grid from '@mui/material/Grid';
import { ROUTES } from 'src/app/consts/routes';
import { FileStorageExpiringEnum } from 'src/generated';
import { useAppSelector } from 'src/hooks/useAppSelector';
import { DOCUMENTS_STATUS_OPTIONS } from 'src/pages/documents/const';
import { IDocumentListQueryParams } from 'src/pages/documents/types';
import React, { FC, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import selector from './selector';

const DocumentsListPanelTabs: FC = () => {
  const { boatId, selectedNode } = useAppSelector(selector);
  const { status } = useQuery<IDocumentListQueryParams>();
  const history = useHistory();

  useEffect(() => {
    if (!status) {
      history.replace(
        updateQuery<IDocumentListQueryParams>({
          status: DOCUMENTS_STATUS_OPTIONS.ALL,
        })
      );
    }
  }, [boatId]);

  const tabs: ITab[] = [
    {
      value: DOCUMENTS_STATUS_OPTIONS.ALL,
      label: <FormattedMessage id="documents.tabs.all" />,
      dataTestId: 'documents-all-documents-tab',
      to: buildURL<IDocumentListQueryParams>(`/${boatId}/${ROUTES.DOCUMENTS}`, {
        status: DOCUMENTS_STATUS_OPTIONS.ALL,
        id: selectedNode ? String(selectedNode.id) : undefined,
      }),
    },
    {
      value: DOCUMENTS_STATUS_OPTIONS.EXPIRED,
      label: <FormattedMessage id="documents.tabs.expired" />,
      dataTestId: 'documents-already-expired-tab',
      to: buildURL<IDocumentListQueryParams>(`/${boatId}/${ROUTES.DOCUMENTS}`, {
        status: DOCUMENTS_STATUS_OPTIONS.EXPIRED,
        id: selectedNode?.data?.status === FileStorageExpiringEnum.EXPIRED ? String(selectedNode.id) : undefined,
      }),
    },
    {
      value: DOCUMENTS_STATUS_OPTIONS.EXPIRE_SOON,
      label: <FormattedMessage id="documents.tabs.expire_soon" />,
      dataTestId: 'documents-expire-soon-tab',
      to: buildURL<IDocumentListQueryParams>(`/${boatId}/${ROUTES.DOCUMENTS}`, {
        status: DOCUMENTS_STATUS_OPTIONS.EXPIRE_SOON,
        id: selectedNode?.data?.status === FileStorageExpiringEnum.EXPIRE_SOON ? String(selectedNode.id) : undefined,
      }),
    },
  ];

  return (
    <Grid item container>
      <Tabs size="small" tabs={tabs} active={status} />
    </Grid>
  );
};

export default DocumentsListPanelTabs;
