import { COLOURS } from '@core-ui/styles';
import { SxProps, Theme } from '@mui/material/styles';
import { TRANSITION_TIMING_FUNCTION } from 'src/app/consts/app';

export default (nodeUpdating: boolean): Record<string, SxProps<Theme>> => ({
  container: {
    overflow: 'scroll',

    ul: {
      margin: 0,
      padding: 0,
      listStyle: 'none',
    },

    li: {
      borderRadius: 2,
      transition: 'background-color 0.1s ease-in-out',
    },

    '.tree-root': {
      transition: `all 250ms ${TRANSITION_TIMING_FUNCTION}`,
      filter: nodeUpdating ? 'opacity(50%)' : 'none',
    },

    '.drop-target': {
      borderRadius: 2,
      backgroundColor: COLOURS.HUE_COCONUT.HUE_4,
    },

    '.dragging-source > div': {
      borderRadius: 2,
      backgroundColor: COLOURS.HUE_COCONUT.HUE_50,
    },

    '.tree-container': {
      borderRadius: 2,
      width: '100%',
    },
  },

  treesContainer: {
    overflow: 'scroll',
  },

  uploadFilesButton: {
    borderRadius: '16px !important',
  },

  createFolderButton: {
    borderRadius: '16px !important',
    borderColor: `${COLOURS.HUE_COCONUT.HUE_32} !important`,
    color: `${COLOURS.HUE_COCONUT.HUE_172} !important`,

    '&:hover': {
      background: `${COLOURS.WHITE} !important`,
      borderColor: `${COLOURS.HUE_COCONUT.HUE_100} !important`,
      color: `${COLOURS.HUE_COCONUT.HUE_195} !important`,
    },
  },
});
