import Button from '@core-ui/button';
import Modal from '@core-ui/modal';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import { useAppSelector } from 'src/hooks/useAppSelector';
import { deleteNode } from 'src/pages/documents/Modals/DeleteNodeModal/actions';
import { Trash } from '@phosphor-icons/react';
import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import selector from './selector';

interface IProps {
  opened: boolean;
  onClose: () => void;
}

const DeleteNodeModal: FC<IProps> = (props) => {
  const { opened, onClose } = props;

  const { node, loading } = useAppSelector(selector);
  const dispatch = useDispatch();

  const handleConfirm = () => {
    // TODO: переписать эту пару экшен-сага для передачи только необходимых параметров а не всей ноды
    dispatch(deleteNode({ node }));
  };

  let title = 'modal.documents.delete_document.title';
  let subtitle = 'modal.documents.delete_document.subtitle';

  // only folders have droppable property and it's always true
  if (node?.droppable) {
    title = 'modal.documents.delete_folder.title';
    subtitle = 'modal.documents.delete_folder.subtitle';
  }

  return (
    <Modal
      open={opened}
      maxWidth="xs"
      onClose={onClose}
      title={<FormattedMessage id={title} />}
      subtitle={<FormattedMessage id={subtitle} />}
    >
      <DialogContent>
        <Grid container justifyContent="flex-end" gap={3} component={DialogActions} disableSpacing>
          <Button
            color="blue"
            size="large"
            variant="outlined"
            onClick={onClose}
            label={<FormattedMessage id="label.cancel" />}
          />

          <Button
            color="blue"
            size="large"
            variant="contained"
            autoFocus
            onClick={handleConfirm}
            loading={loading}
            disabled={loading}
            label={<FormattedMessage id="label.delete" />}
            startIcon={<Trash />}
          />
        </Grid>
      </DialogContent>
    </Modal>
  );
};

export default DeleteNodeModal;
