import { all, fork } from 'redux-saga/effects';
import appSagas from './app/sagas';
import topMenuHeaderSagas from './components/TopMenuHeader/sagas';
import dictionarySagas from './dictionary/sagas';
import analyticsSagas from './pages/analytics/sagas';
import backofficeSagas from './pages/backoffice/sagas';
import chatsSagas from './pages/chats/sagas';
import documentsSagas from './pages/documents/sagas';
import financesSagas from './pages/finances/sagas';
import loginSagas from './pages/login/sagas';
import settingsSagas from './pages/settings/sagas';

export default function* root() {
  yield all([
    fork(appSagas),
    fork(backofficeSagas),
    fork(chatsSagas),
    fork(analyticsSagas),
    fork(dictionarySagas),
    fork(topMenuHeaderSagas),
    fork(financesSagas),
    fork(settingsSagas),
    fork(documentsSagas),
    fork(loginSagas),
  ]);
}
