import { COLOURS } from '@core-ui/styles';
import { PAGE_PADDING } from 'src/pages/documents/const';
import { CSSProperties } from 'react';

export default (): Record<string, CSSProperties> => ({
  container: {
    padding: `${PAGE_PADDING}px`,
    // TODO: этот падинг будет нужен для поиска когда мы его добавим
    // paddingTop: 36,
    background: COLOURS.HUE_COCONUT.HUE_24,
  },
});
