import { ICreateFolderModalData } from 'src/pages/documents/Modals/types';

export default class CreateFolderModalData implements ICreateFolderModalData {
  parent: number;
  shared: boolean;

  constructor(parent: number, shared: boolean) {
    this.parent = parent;
    this.shared = shared;
  }
}
