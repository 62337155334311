import Immutable from '@core-ui/immutable';
import reducer from '@core-ui/reducers_handlers';
import { Nullable } from '@core-ui/types';
import { handleActions } from 'redux-actions';
import * as actions from './actions';
import { CreateFolderModalData, DeleteNodeModalData, RenameNodeModalData } from './command';

export type IModalsData = CreateFolderModalData | DeleteNodeModalData | RenameNodeModalData;

export interface IModalsState {
  data: Nullable<IModalsData>;
  opened: boolean;
  loading: boolean;
}

const defaultState = Immutable<IModalsState>({
  data: null,
  opened: false,
  loading: false,
});

export default handleActions<typeof defaultState, any>(
  {
    // side effect for resetting loading state on modal close
    [actions.closeDocumentsModal.toString()]: (state) => {
      return state.set('loading', false);
    },
  },
  reducer<typeof defaultState>(actions.reducerName, defaultState)
);
