import { Nullable } from '@core-ui/types';
import { FLEET } from 'src/pages/documents/const';
import { FileTreeNode } from 'src/pages/documents/types';

// recursive function for going up the node tree and building parent ids array
export const buildParentsIdArray = (
  allNodes: Nullable<FileTreeNode[]>,
  currentNode: Nullable<FileTreeNode> | undefined,
  parentsIds: (number | string)[] = []
): (number | string)[] => {
  if (currentNode?.parent) {
    parentsIds.push(currentNode.parent);

    const parent = allNodes?.find((node) => node.id === currentNode.parent);

    if (parent) {
      buildParentsIdArray(allNodes, parent, parentsIds);
    }
  }

  return parentsIds;
};

// recursive function for going down the node tree and building child ids array
export const buildChildrenIdArray = (
  items: Nullable<FileTreeNode[]>,
  parentNode: FileTreeNode,
  childrenIds: number[] = []
) => {
  items?.forEach((item) => {
    // 0 and FLEET are root and fleet nodes, they should not be deleted
    if (item.id !== 0 && item.id !== FLEET && item.parent === parentNode.id) {
      childrenIds.push(Number(item.id));

      if (item.droppable) {
        buildChildrenIdArray(items, item, childrenIds);
      }
    }
  });

  return childrenIds;
};
