import { useQuery } from '@core-ui/redux-router';
import { updateQuery } from '@core-ui/url';
import { DOCUMENTS_STATUS_OPTIONS } from 'src/pages/documents/const';
import AllDocuments from 'src/pages/documents/DocumentsList/components/AllDocuments';
import ExpiredDocuments from 'src/pages/documents/DocumentsList/components/ExpiredDocuments';
import ExpireSoonDocuments from 'src/pages/documents/DocumentsList/components/ExpireSoonDocuments';
import { IDocumentListQueryParams } from 'src/pages/documents/types';
import React, { FC, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

const DocumentsListContent: FC = () => {
  const { status } = useQuery<IDocumentListQueryParams>();
  const history = useHistory();

  useEffect(() => {
    if (!status) {
      history.replace(updateQuery<IDocumentListQueryParams>({ status: DOCUMENTS_STATUS_OPTIONS.ALL }));
    }
  }, []);

  switch (status) {
    case DOCUMENTS_STATUS_OPTIONS.ALL:
      return <AllDocuments />;
    case DOCUMENTS_STATUS_OPTIONS.EXPIRED:
      return <ExpiredDocuments />;
    case DOCUMENTS_STATUS_OPTIONS.EXPIRE_SOON:
      return <ExpireSoonDocuments />;
    default:
      return <AllDocuments />;
  }
};

export default DocumentsListContent;
