import { LogoIconOcean } from '@core-ui/icons';
import { COLOURS, HEIGHT_SIZE } from '@core-ui/styles';
import Grid from '@mui/material/Grid';
import React from 'react';
import LoginForm from '../LoginForm';

const LoginRoot = () => {
  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      height={`calc(100vh - (${HEIGHT_SIZE.headerHeight}px))`}
    >
      <Grid item container direction="column" gap={8}>
        <Grid item container justifyContent="center">
          <LogoIconOcean fill={COLOURS.HUE_COCONUT.HUE_195} width={155} height={30} />
        </Grid>

        <Grid item container justifyContent="center">
          <LoginForm />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default LoginRoot;
