import Button from '@core-ui/button';
import Grid from '@mui/material/Grid';
import SvgIcon from '@mui/material/SvgIcon';
import { TextFieldForm } from 'src/components/Form';
import { useAppSelector } from 'src/hooks/useAppSelector';
import { FORM_WIDTH } from 'src/pages/login/consts';
import { login, setLoginErrors, toggleShowPassword } from 'src/pages/login/LoginForm/actions';
import { Eye, EyeSlash } from '@phosphor-icons/react';
import React from 'react';
import { Field, Form } from 'react-final-form';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { ILoginForm } from '../types';
import selector from './selector';
import useStyles from './styles';
import validate from './validate';

const LoginForm = () => {
  const { loading, showPassword, hasLoginErrors } = useAppSelector(selector);

  const dispatch = useDispatch();
  const styles = useStyles();

  const onSubmit = (values: ILoginForm) => {
    dispatch(login(values));
  };

  const handleShowPassword = () => {
    dispatch(toggleShowPassword());
  };

  const handleFormChange = () => {
    if (hasLoginErrors) {
      dispatch(setLoginErrors(false));
    }
  };

  const showHidePasswordIcon = (
    <SvgIcon
      onClick={handleShowPassword}
      sx={styles.showHidePasswordIcon}
    >
      {showPassword ? <EyeSlash /> : <Eye />}
    </SvgIcon>
  );

  return (
    <Form onSubmit={onSubmit} validate={(values) => validate(values, hasLoginErrors)}>
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit} onChange={handleFormChange}>
          <Grid
            container
            direction="column"
            alignItems="center"
            width={FORM_WIDTH}
            marginX="auto"
            justifyContent="center"
            rowGap={6}
          >
            <Grid item container>
              <Field
                name="email"
                disabled={loading}
                component={TextFieldForm}
                type="email"
                fullWidth
                label={<FormattedMessage id="label.email" />}
              />
            </Grid>

            <Grid item container>
              <Field
                name="password"
                disabled={loading}
                component={TextFieldForm}
                type={showPassword ? 'text' : 'password'}
                fullWidth
                label={<FormattedMessage id="label.password" />}
                rightIcon1={showHidePasswordIcon}
              />
            </Grid>

            <Grid item marginTop={2}>
              <Button
                type="submit"
                color="black"
                disabled={loading}
                loading={loading}
                fullWidth
                size="large"
                variant="contained"
                label={<FormattedMessage id="label.login" />}
              />
            </Grid>
          </Grid>
        </form>
      )}
    </Form>
  );
};

export default LoginForm;
