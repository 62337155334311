import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import React, { FC } from 'react';

const DocumentInfoSkeleton: FC = () => {
  return (
    <Grid container direction="column" gap={4} padding={6}>
      <Skeleton height={48} width="100%" />
      <Skeleton height={48} width="100%" />
      <Skeleton height={48} width="100%" />
      <Skeleton height={48} width="100%" />
      <Skeleton height={48} width="100%" />
    </Grid>
  );
};

export default DocumentInfoSkeleton;
