import Grid from '@mui/material/Grid';
import ImagesVerticalCarousel from 'src/components/ImagesVerticalCarousel';
import NoDataContainer from 'src/components/NoDataContainer';
import { useAppSelector } from 'src/hooks/useAppSelector';
import PreviewPlaceholder from 'src/pages/documents/DocumentPreview/components/PreviewPlaceholder';
import SelectedNodePlaceholder from 'src/pages/documents/DocumentPreview/components/SelectedNodePlaceholder';
import { getNoDocumentPreviews, getNoSelectedNode } from 'src/pages/documents/DocumentPreview/selector';
import { getSelectedNode } from 'src/pages/documents/DocumentsList/selector';
import React, { FC } from 'react';

const DocumentPreview: FC = () => {
  const selectedDocument = useAppSelector(getSelectedNode);

  return (
    <NoDataContainer selector={getNoSelectedNode} noDataContent={<SelectedNodePlaceholder />}>
      <NoDataContainer selector={getNoDocumentPreviews} noDataContent={<PreviewPlaceholder />}>
        <Grid item container wrap="nowrap" position="relative" marginY={6}>
          <ImagesVerticalCarousel
            defaultWidth={300}
            documentId={selectedDocument?.id}
            imageLinks={selectedDocument?.data?.image_links}
            hideDownloadButton
          />
        </Grid>
      </NoDataContainer>
    </NoDataContainer>
  );
};

export default DocumentPreview;
