import 'core-js/stable';
import React from 'react';
import * as ReactDOM from 'react-dom';
import 'regenerator-runtime/runtime';
import App from './app/App';
import { initSentry } from './sentry';

initSentry();

ReactDOM.render(<App />, document.getElementById('root'));
