import info, { IDocumentsInfoState } from 'src/pages/documents/DocumentInfo/reducer';
import list, { IDocumentsListState } from 'src/pages/documents/DocumentsList/reducer';
import modals, { IModalsState } from 'src/pages/documents/Modals/reducer';
import { combineReducers } from 'redux';

export interface IDocumentsState {
  modals: IModalsState;
  list: IDocumentsListState;
  info: IDocumentsInfoState;
}

export default combineReducers({
  modals,
  list,
  info,
});
