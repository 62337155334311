import { FileTreeNode } from 'src/pages/documents/types';
import { createAction } from 'redux-actions';

export const reducerName = 'documents.modals.rename_single_node';

export interface IRenameNode {
  node: FileTreeNode;
  newName: string;
}

export const renameNode = createAction<IRenameNode>(`${reducerName}.CONFIRM`);
