import { Nullable } from '@core-ui/types';
import Grid from '@mui/material/Grid';
import NoDataContainer from 'src/components/NoDataContainer';
import NoDataPlaceholder from 'src/components/NoDataPlaceholder';
import { FileStorageExpiringEnum } from 'src/generated';
import { useAppSelector } from 'src/hooks/useAppSelector';
import { changeSelectedNode, setSelectedNode } from 'src/pages/documents/DocumentsList/actions';
import ExpireListItem from 'src/pages/documents/DocumentsList/components/ExpireListItem';
import ListSkeleton from 'src/pages/documents/DocumentsList/components/skeletons';
import { getExpiredTabsNoData, getExpireTabsData } from 'src/pages/documents/DocumentsList/selector';
import { FileTreeNode } from 'src/pages/documents/types';
import React, { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';

const ExpiredDocuments: FC = () => {
  const { items, selectedNode } = useAppSelector(getExpireTabsData);

  const dispatch = useDispatch();

  useEffect(() => {
    if (selectedNode?.data?.status !== FileStorageExpiringEnum.EXPIRED) {
      dispatch(setSelectedNode(null));
    }
  }, []);

  const handleSelectNode = (node: Nullable<FileTreeNode>) => {
    dispatch(changeSelectedNode(node));
  };

  return (
    <NoDataContainer
      selector={getExpiredTabsNoData}
      loader={<ListSkeleton />}
      noDataContent={<NoDataPlaceholder subtitleId={null} />}
    >
      <Grid
        item
        container
        direction="column"
        wrap="nowrap"
        gap={1}
      >
        {items?.map((item) => (
          <ExpireListItem
            node={item}
            status={FileStorageExpiringEnum.EXPIRED}
            isSelected={item.id === selectedNode?.id}
            onSelect={handleSelectNode}
            key={item.id}
          />
        ))}
      </Grid>
    </NoDataContainer>
  );
};

export default ExpiredDocuments;
