import { getList, resetState, setList, setLoading } from '@core-ui/reducers_handlers';
import { Nullable } from '@core-ui/types';
import { DropOptions } from '@minoru/react-dnd-treeview';
import { FileStorageNodeData } from 'src/generated';
import { FileTreeNode } from 'src/pages/documents/types';
import { createAction } from 'redux-actions';

export const reducerName = 'documents.list';

export const resetDocumentsState = resetState(reducerName);

// we have separate loading flags for whole list loading and a single node being updated
export const setDocumentsListLoading = setLoading(reducerName);
export const setNodeUpdating = createAction<boolean>(`${reducerName}.SET_NODE_UPDATING`);

export const getDocumentsSectionList = getList(reducerName);
export const setDocumentsSectionList = setList<FileTreeNode>(reducerName);

export const changeSelectedNode = createAction<Nullable<FileTreeNode>>(`${reducerName}.CHANGE_SELECTED_NODE`);
export const setSelectedNode = createAction<Nullable<FileTreeNode>>(`${reducerName}.SET_SELECTED_NODE`);

export const setInitialOpenedNodes = createAction<(number | string)[]>(`${reducerName}.SET_INITIAL_OPENED_NODES`);

export const addSingleNode = createAction<FileTreeNode>(`${reducerName}.ADD_SINGLE_NODE`);
export const deleteSingleNode = createAction<FileTreeNode>(`${reducerName}.DELETE_SINGLE_NODE`);
export const deleteMultipleNodes = createAction<number[]>(`${reducerName}.DELETE_MULTIPLE_NODES`);

export interface IUploadFiles {
  files: File[];
  parent: number | string;
  shared: boolean;
}

export const uploadFiles = createAction<IUploadFiles>(`${reducerName}.UPLOAD_FILE`);

export interface ICreateFolder {
  parent: number | string;
  text: string;
  shared: boolean;
}

export const createFolder = createAction<ICreateFolder>(`${reducerName}.CREATE_FOLDER`);

export const updateNodeParent = createAction<DropOptions<FileStorageNodeData>>(`${reducerName}.UPDATE_NODE_PARENT`);
