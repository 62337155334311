import State from 'src/app/types/state';
import { NoDataContainerSelector } from 'src/components/NoDataContainer/NoDataContainer';
import { FileStorageNodeUploadedBy } from 'src/generated';
import { FLEET } from 'src/pages/documents/const';
import {
  getCurrentItems,
  getDocumentsList,
  getLoading,
  getNodeUpdating,
  getSelectedNode,
} from 'src/pages/documents/DocumentsList/selector';
import { FileTreeNode } from 'src/pages/documents/types';
import { createSelector } from 'reselect';

const getInitialOpenedNodes = (state: State) => state.documents.list.initialOpenedNodes;

const getFiltratedItems = createSelector([getCurrentItems], (currentItems) => {
  const boatItems: FileTreeNode[] = [];
  const fleetItems: FileTreeNode[] = [
    {
      id: FLEET,
      text: 'Fleet',
      parent: 0,
      droppable: true,
      data: {
        shared: true,
        file_type: 'folder',
        // uploaded_by, uploaded_at and image_links are required by the interface, but we don't need them in this node
        uploaded_by: {} as FileStorageNodeUploadedBy,
        uploaded_at: 0,
        image_links: [],
      },
    },
  ];

  currentItems?.forEach((item) => {
    if (item.data?.shared) {
      fleetItems.push(item);
    } else {
      boatItems.push(item);
    }
  });

  return {
    boatItems,
    fleetItems,
  };
});

// we use 2 separate no data selectors because we need to show placeholder if there are
// no items in the boat tree and at the same time always show the fleet tree
export const getBoatFileTreeNoData: NoDataContainerSelector = createSelector(
  [getDocumentsList, getFiltratedItems],
  (allItems, filtratedItems) => ({
    loading: allItems.loading,
    loaded: allItems.loaded,
    hasData: Boolean(filtratedItems.boatItems.length),
    error: allItems.error,
  })
);

export const getFleetFileTreeNoData: NoDataContainerSelector = createSelector([getDocumentsList], (allItems) => ({
  loading: allItems.loading,
  loaded: allItems.loaded,
  hasData: true,
  error: allItems.error,
}));

export default createSelector(
  [getFiltratedItems, getSelectedNode, getInitialOpenedNodes, getNodeUpdating, getLoading],
  (filtratedItems, selectedNode, initialOpenedNodes, nodeUpdating, listLoading) => ({
    boatItems: filtratedItems.boatItems,
    fleetItems: filtratedItems.fleetItems,
    selectedNode,
    initialOpenedNodes,
    nodeUpdating,
    listLoading,
  })
);
