import Button from '@core-ui/button';
import Modal from '@core-ui/modal';
import TextField from '@core-ui/text_field';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import { useAppSelector } from 'src/hooks/useAppSelector';
import { createFolder } from 'src/pages/documents/DocumentsList/actions';
import React, { FC, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import selector from './selector';

interface IProps {
  opened: boolean;
  onClose: () => void;
}

const CreateFolderModal: FC<IProps> = (props) => {
  const { opened, onClose } = props;

  const { parent, shared, loading } = useAppSelector(selector);
  const [name, setName] = useState<string>('');

  const dispatch = useDispatch();

  useEffect(() => {
    if (opened) {
      setName('');
    }
  }, [opened]);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    dispatch(
      createFolder({
        parent,
        text: name,
        shared,
      })
    );
  };

  return (
    <Modal
      open={opened}
      maxWidth="xxs"
      onClose={onClose}
      title={<FormattedMessage id="modal.documents.create_folder.title" />}
    >
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <TextField
            fullWidth
            size="medium"
            autoFocus
            label={<FormattedMessage id="modal.documents.create_folder.folder_name" />}
            value={name}
            // @ts-ignore
            onChange={setName}
            name="name"
          />

          <Grid container justifyContent="flex-end" gap={3} component={DialogActions} disableSpacing>
            <Button
              color="blue"
              size="large"
              variant="contained"
              type="submit"
              loading={loading}
              disabled={loading}
              label={<FormattedMessage id="label.create" />}
            />
          </Grid>
        </form>
      </DialogContent>
    </Modal>
  );
};

export default CreateFolderModal;
