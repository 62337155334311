import { COLOURS, HEIGHT_SIZE } from '@core-ui/styles';
import { Nullable } from '@core-ui/types';
import { getParsedQuery, updateQuery } from '@core-ui/url';
import Container from '@mui/material/Container';
import { SxProps, Theme } from '@mui/material/styles';
import { FAQ_URL, STATIC_URL } from 'src/app/consts/app';
import { FAQQueryParams } from 'src/pages/faq/types';
import { hi } from 'date-fns/locale';
import React, { CSSProperties, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';

const containerStyles: SxProps<Theme> = {
  height: `calc(100vh - (${HEIGHT_SIZE.headerHeight}px))`,
  padding: '0 !important',
};

const iFrameStyles: CSSProperties = {
  flex: 1,
  width: '100%',
  backgroundColor: COLOURS.WHITE,
  border: 'none',
};

const FAQRoot = () => {
  const history = useHistory();
  const iframeRef = useRef<Nullable<HTMLIFrameElement>>(null);

  // TODO: нам точно нужен этот query параметр? Это что-то связанное со статическим сервером и iframe?
  const { faqURL } = getParsedQuery<FAQQueryParams>();

  useEffect(() => {
    let loaded = false;

    const onLoad = () => {
      if (loaded) {
        return;
      }

      const document = iframeRef.current!.contentDocument;

      // TODO: это мб не работает, проверить, если
      if (document) {
        document.body.style.maxWidth = '1400px';
      }

      if (faqURL) {
        iframeRef.current!.contentWindow?.postMessage(faqURL, '*');
      }

      loaded = true;
    };

    const onMessage = (event: MessageEvent) => {
      if (event.origin === STATIC_URL) {
        history.replace(updateQuery<FAQQueryParams>({ faqURL: event.data }));
      }
    };

    iframeRef.current?.addEventListener('load', onLoad);
    window.addEventListener('message', onMessage);

    return () => {
      iframeRef.current?.removeEventListener('load', onLoad);
      window.removeEventListener('message', onMessage);
    };
  }, []);

  return (
    <Container maxWidth="xl" sx={containerStyles}>
      <iframe title="FAQ page" ref={iframeRef} src={faqURL ? STATIC_URL + faqURL : FAQ_URL} style={iFrameStyles} />
    </Container>
  );
};

export default FAQRoot;
