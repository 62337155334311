import { Route } from '@core-ui/redux-router';
import { ROUTES } from 'src/app/consts/routes';
import State from 'src/app/types/state';
import { getDocumentsSectionList, resetDocumentsState } from 'src/pages/documents/DocumentsList/actions';
import DocumentsRoot from 'src/pages/documents/DocumentsRoot';

const key: keyof State = 'documents';

const routes: Route[] = [
  {
    key,
    path: `/:boatId([0-9]+)/:route(${ROUTES.DOCUMENTS})`,
    exact: true,
    component: DocumentsRoot,
    effects: [
      { events: ['always'], trigger: resetDocumentsState },
      { events: ['always'], trigger: getDocumentsSectionList },
    ],
  },
];

export default routes;
