import { COLOURS } from '@core-ui/styles';
import { SxProps, Theme } from '@mui/material/styles';
import { IProps } from './ExpireListItem';

export default (props?: IProps): Record<string, SxProps<Theme>> => ({
  container: {
    flexWrap: 'nowrap',
    backgroundColor: props?.isSelected ? COLOURS.HUE_FIG.HUE_15 : 'transparent',
    border: props?.isSelected ? `1px solid ${COLOURS.HUE_FIG.HUE_100}` : '1px solid transparent',
    borderRadius: 2,
    transition: 'background-color 0.1s ease-in-out, border 0.1s ease-in-out',

    '&:hover': {
      backgroundColor: props?.isSelected ? COLOURS.HUE_FIG.HUE_15 : COLOURS.HUE_COCONUT.HUE_12,
    },
  },
  contentContainer: {
    padding: 2,
    flexWrap: 'nowrap',
    cursor: props?.isSelected ? 'default' : 'pointer',
  },
  text: {
    transition: 'all 0.1s ease-in-out',
  },
  icon: {
    transition: 'all 0.1s ease-in-out',
  },
});
