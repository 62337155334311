import Button from '@core-ui/button';
import { parseDate } from '@core-ui/dates';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { DATE_FORMAT } from 'src/app/consts/app';
import { DatePickerForm, TextFieldForm } from 'src/components/Form';
import { useAppSelector } from 'src/hooks/useAppSelector';
import { updateDocumentInfo } from 'src/pages/documents/DocumentInfo/actions';
import { getDocumentsInfoFormData } from 'src/pages/documents/DocumentInfo/selector';
import validate from 'src/pages/documents/DocumentInfo/validate';
import { openDocumentsModal } from 'src/pages/documents/Modals/actions';
import { DeleteNodeModalData } from 'src/pages/documents/Modals/command';
import { IDocumentInfoFormValues } from 'src/pages/documents/types';
import { Trash } from '@phosphor-icons/react';
import React, { FC, KeyboardEvent } from 'react';
import { Field, Form } from 'react-final-form';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';

const DocumentsInfoForm: FC = () => {
  const { node, initialValues, loading } = useAppSelector(getDocumentsInfoFormData);

  const dispatch = useDispatch();

  const handleDeleteDocument = () => {
    if (node) {
      dispatch(openDocumentsModal(new DeleteNodeModalData(node)));
    }
  };

  const handleSubmitForm = (values: IDocumentInfoFormValues) => {
    dispatch(updateDocumentInfo(values));
  };

  const onKeyDown = (event: KeyboardEvent<HTMLFormElement>, handleSubmit: () => void) => {
    if (event.key === 'Enter' && (event.ctrlKey || event.metaKey)) {
      handleSubmit();
    }
  };

  return (
    <Form<IDocumentInfoFormValues>
      subscription={{ dirty: true }}
      initialValues={initialValues}
      validate={validate}
      onSubmit={handleSubmitForm}
    >
      {({ handleSubmit, dirty }) => {
        return (
          <Grid
            container
            component="form"
            direction="column"
            wrap="nowrap"
            flex={1}
            justifyContent="space-between"
            onSubmit={handleSubmit}
            onKeyDown={(e: KeyboardEvent<HTMLFormElement>) => onKeyDown(e, handleSubmit)}
          >
            <Grid item container direction="column" gap={4}>
              <Grid item>
                <Typography fontWeight="semiBold">
                  <FormattedMessage id="label.base_info" />
                </Typography>
              </Grid>

              <Grid item>
                <Field
                  name="name"
                  disabled={loading}
                  component={TextFieldForm}
                  label={<FormattedMessage id="label.document_name" />}
                  fullWidth
                />
              </Grid>

              <Grid item>
                <Field
                  name="expires_at"
                  disabled={loading}
                  dateTimeFormat={DATE_FORMAT}
                  component={DatePickerForm}
                  format={parseDate}
                  openOnFocus
                  size="medium"
                  label={<FormattedMessage id="label.expiry_date" />}
                  fullWidth
                />
              </Grid>

              <Grid item>
                <Field
                  name="description"
                  disabled={loading}
                  component={TextFieldForm}
                  multiline
                  minRows={3}
                  maxRows={5}
                  label={<FormattedMessage id="label.description" />}
                  fullWidth
                />
              </Grid>
            </Grid>

            <Grid item container justifyContent="space-between">
              <Button
                variant="text"
                color="blue"
                size="large"
                onClick={handleDeleteDocument}
                disabled={loading}
                label={<FormattedMessage id="label.delete_document" />}
                startIcon={<Trash />}
              />

              {dirty && (
                <Button
                  variant="contained"
                  color="blue"
                  size="large"
                  onClick={handleSubmit}
                  disabled={loading}
                  loading={loading}
                  label={<FormattedMessage id="label.save" />}
                />
              )}
            </Grid>
          </Grid>
        );
      }}
    </Form>
  );
};

export default DocumentsInfoForm;
