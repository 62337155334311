import { FileTreeNode } from 'src/pages/documents/types';
import { createAction } from 'redux-actions';

export const reducerName = 'documents.modals.rename_single_node';

export interface IDeleteNode {
  node: FileTreeNode;
}

export const deleteNode = createAction<IDeleteNode>(`${reducerName}.DELETE_NODE`);
