import { IDocumentInfoFormValues } from 'src/pages/documents/types';
import { ValidationErrors } from 'final-form';
import { FormattedMessage } from 'react-intl';

export default (values: IDocumentInfoFormValues): ValidationErrors => {
  const errors: ValidationErrors = {};

  if (!values.name) {
    errors.name = <FormattedMessage id="form.errors.required" />;
  }

  return errors;
};
