import form, { ILoginFormState } from 'src/pages/login/LoginForm/reducer';
import { combineReducers } from 'redux';

export interface ILoginState {
  form: ILoginFormState;
}

export default combineReducers({
  form,
});
