import reducer from '@core-ui/reducers_handlers';
import { Action, handleActions } from 'redux-actions';
import Immutable from '@core-ui/immutable';
import * as actions from './actions';

export interface ILoginFormState {
  loading: boolean;
  showPassword: boolean;
  hasLoginErrors: boolean;
}

const defaultState = Immutable<ILoginFormState>({
  loading: false,
  showPassword: false,
  hasLoginErrors: false,
});

export default handleActions<typeof defaultState, any>(
  {
    [actions.toggleShowPassword.toString()]: (state) => state.set('showPassword', !state.showPassword),
    [actions.setLoginErrors.toString()]: (state, { payload }: Action<boolean>) => state.set('hasLoginErrors', payload),
  },
  reducer<typeof defaultState>(actions.reducerName, defaultState)
);
