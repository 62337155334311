import { COLOURS } from '@core-ui/styles';
import { SxProps, Theme } from '@mui/material/styles';
import { IProps } from './SingleNode';

export default (props: IProps): Record<string, SxProps<Theme>> => ({
  container: {
    backgroundColor: props.isSelected ? COLOURS.HUE_FIG.HUE_15 : 'transparent',
    border: props.isSelected ? `1px solid ${COLOURS.HUE_FIG.HUE_100}` : '1px solid transparent',
    transition: 'background-color 0.1s ease-in-out, border 0.1s ease-in-out',

    '&:hover': {
      backgroundColor: props.isSelected ? COLOURS.HUE_FIG.HUE_15 : COLOURS.HUE_COCONUT.HUE_12,
      cursor: props.isSelected ? 'default' : 'pointer',
    },
  },
  fleetEmptyBox: {
    cursor: 'pointer',
    height: 36,
    minWidth: 36,
    width: 36,
  },
  dragIcon: {
    cursor: props.nodeUpdating ? 'wait' : 'grab',
    height: 36,
    width: 36,
  },
  innerContainer: {
    cursor: 'default',
  },
  content: {
    cursor: props.isSelected ? 'default' : 'pointer',
  },
  text: {
    paddingTop: '1px',
    wordBreak: 'break-all',
    transition: 'all 0.1s ease-in-out',
  },
  expandButtonIcon: {
    transition: 'transform 0.1s ease-in-out',
    transform: props.isOpen ? 'rotate(0)' : 'rotate(90deg)',
  },
});
