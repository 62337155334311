import { Nullable } from '@core-ui/types';
import { buildURL } from '@core-ui/url';
import { PATCH } from 'src/api/oceanApi';
import { responseError } from 'src/app/sagas';
import { vehicleIdSelector } from 'src/app/selectors';
import { throwError } from 'src/app/utils/app';
import { showNotification } from 'src/components/Notification/actions';
import { ChangeFileStorageNodeModel } from 'src/generated';
import { DOCUMENTS_REQUEST_QUERY_PARAMS, FILE_STORAGE } from 'src/pages/documents/const';
import { setDocumentsSectionList, setSelectedNode } from 'src/pages/documents/DocumentsList/actions';
import { getCurrentItems, getSelectedNode } from 'src/pages/documents/DocumentsList/selector';
import { closeDocumentsModal, setDocumentsModalLoading } from 'src/pages/documents/Modals/actions';
import { FileTreeNode } from 'src/pages/documents/types';
import { all } from '@redux-saga/core/effects';
import isNil from 'lodash-es/isNil';
import { Action } from 'redux-actions';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { asMutable } from '@core-ui/immutable';
import * as actions from './actions';
import { IRenameNode } from './actions';

function* renameNode({ payload }: Action<IRenameNode>) {
  try {
    const { node, newName } = payload;

    yield put(setDocumentsModalLoading({ loading: true }));

    const formattedNewName = newName.trim();

    if (formattedNewName === '') {
      throwError('Name must not be empty');
    }

    const boatId: number = yield select(vehicleIdSelector);
    const selectedNode: Nullable<FileTreeNode> = yield select(getSelectedNode);
    const currentItems: Nullable<FileTreeNode[]> = yield select(getCurrentItems);
    const mutableCurrentItems = asMutable(currentItems, { deep: true });

    const updatedItemIndex = mutableCurrentItems?.findIndex((item) => item.id === node.id);

    const updatedNodeName = node.droppable ? formattedNewName : `${formattedNewName}.${node?.text?.split('.').pop()}`;

    if (mutableCurrentItems && !isNil(updatedItemIndex)) {
      const url = buildURL(`/${FILE_STORAGE}/${node.id}`, {
        [DOCUMENTS_REQUEST_QUERY_PARAMS.BOAT_ID]: boatId,
      });

      yield call(PATCH, url, {
        text: updatedNodeName,
      } as ChangeFileStorageNodeModel);

      mutableCurrentItems[updatedItemIndex].text = updatedNodeName;

      if (selectedNode?.id === node.id) {
        yield put(setSelectedNode(mutableCurrentItems[updatedItemIndex]));
      }

      yield all([
        put(closeDocumentsModal()),
        put(
          setDocumentsSectionList({
            value: mutableCurrentItems,
            hasData: true,
            isAllDataReceived: true,
          })
        ),
        put(
          showNotification({
            variant: 'success',
            titleId: node.droppable
              ? 'notification.success.text.rename_folder'
              : 'notification.success.text.rename_document',
          })
        ),
      ]);
    }
  } catch (e) {
    yield all([put(setDocumentsModalLoading({ loading: false })), call(responseError, e)]);
  }
}

export default [takeLatest(actions.renameNode, renameNode)];
