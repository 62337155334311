import { Route } from '@core-ui/redux-router';
import { ROUTES } from 'src/app/consts/routes';
import State from 'src/app/types/state';
import { resetLoginFormLoading } from 'src/pages/login/LoginForm/actions';
import LoginRoot from './LoginRoot';

const key: keyof State = 'login';

const routes: Route[] = [
  {
    key,
    path: `/:route(${ROUTES.LOGIN})`,
    exact: true,
    component: LoginRoot,
    effects: [{ events: ['on_enter'], trigger: resetLoginFormLoading }],
  },
];

export default routes;
