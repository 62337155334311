export const PAGE_PADDING = 24;

export const FILE_TREE_DEFAULT_WIDTH = 374;
export const FILE_TREE_MIN_WIDTH = 374;
export const FILE_TREE_MAX_WIDTH = 510;

export const INFO_PANEL_WIDTH = 374;

export enum DOCUMENTS_STATUS_OPTIONS {
  ALL = 'all',
  EXPIRED = 'expired',
  EXPIRE_SOON = 'expire_soon',
}

export enum DOCUMENTS_REQUEST_QUERY_PARAMS {
  TEXT = 'text',
  BOAT_ID = 'boat_id',
  STATUS = 'status',
}

export const FILE_STORAGE = 'file_storage';

export const FLEET = 'fleet';
