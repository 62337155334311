import State from 'src/app/types/state';
import { getLoading } from 'src/pages/documents/Modals/selector';
import { createSelector } from 'reselect';
import DeleteNodeModalData from '../command/DeleteNodeModalData';

const getModalsData = (state: State) => state.documents.modals.data as DeleteNodeModalData;

export default createSelector([getModalsData, getLoading], (modalData, loading) => ({
  node: modalData?.node,
  loading,
}));
