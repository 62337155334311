import { Route } from '@core-ui/redux-router';
import { ROUTES } from 'src/app/consts/routes';
import FAQRoot from './FAQRoot';

const key = 'faq';

const routes: Route[] = [
  {
    key,
    path: `/:route(${ROUTES.FAQ})`,
    exact: true,
    component: FAQRoot,
  },
];

export default routes;
