import State from 'src/app/types/state';
import { CreateFolderModalData } from 'src/pages/documents/Modals/command';
import { getLoading } from 'src/pages/documents/Modals/selector';
import { createSelector } from 'reselect';

const getModalsData = (state: State) => state.documents.modals.data as CreateFolderModalData;

export default createSelector([getModalsData, getLoading], (modalData, loading) => ({
  parent: modalData?.parent,
  shared: modalData?.shared,
  loading,
}));
