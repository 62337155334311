import { Route } from '@core-ui/redux-router';
import Page404 from './Page404';

const key = '404';

const routes: Route[] = [
  {
    key,
    path: '*',
    component: Page404,
  },
];

export default routes;
