import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { DATE_FORMAT } from 'src/app/consts/app';
import { formatTime } from 'src/app/utils/dates';
import NoDataContainer from 'src/components/NoDataContainer';
import { SidePanelRight } from 'src/components/SidePanel';
import { INFO_PANEL_WIDTH } from 'src/pages/documents/const';
import DocumentInfoSkeleton from 'src/pages/documents/DocumentInfo/components/DocumentInfoSkeleton';
import DocumentsInfoForm from 'src/pages/documents/DocumentInfo/components/DocumentsInfoForm';
import { getSelectedNode } from 'src/pages/documents/DocumentsList/selector';
import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { getNoDataContainerData } from './selector';
import useStyles from './styles';

const DocumentInfo: FC = () => {
  const selectedDocument = useSelector(getSelectedNode);
  const styles = useStyles();

  const documentName = selectedDocument?.text ?? <FormattedMessage id="label.unknown_name" />;

  const uploadDate = selectedDocument?.data?.uploaded_at ? (
    formatTime(selectedDocument?.data?.uploaded_at, { dateFormat: DATE_FORMAT })
  ) : (
    <FormattedMessage id="label.unknown_date" />
  );

  const uploadedBy =
    selectedDocument?.data?.uploaded_by?.first_name ?? selectedDocument?.data?.uploaded_by?.last_name ? (
      `${selectedDocument?.data?.uploaded_by?.first_name} ${selectedDocument?.data?.uploaded_by?.last_name}`
    ) : (
      <FormattedMessage id="label.unknown_user" />
    );

  return (
    <SidePanelRight defaultWidth={372} minWidth={372} maxWidth={372} disableResize>
      <NoDataContainer
        selector={getNoDataContainerData}
        loader={<DocumentInfoSkeleton />}
        noDataContent={<div />}
        height="100%"
      >
        <Grid
          item
          container
          direction="column"
          gap={8}
          padding={6}
          width={INFO_PANEL_WIDTH}
          minWidth={INFO_PANEL_WIDTH}
          sx={styles.container}
        >
          <Grid item container direction="column" gap={1}>
            <Grid item>
              <Typography variant="h4" sx={styles.text}>
                {documentName}
              </Typography>
            </Grid>

            <Grid item>
              <Typography sx={styles.text}>
                <FormattedMessage id="label.uploaded_date_and_by" values={{ date: uploadDate, user: uploadedBy }} />
              </Typography>
            </Grid>
          </Grid>

          <DocumentsInfoForm />
        </Grid>
      </NoDataContainer>
    </SidePanelRight>
  );
};

export default DocumentInfo;
