import * as Sentry from '@sentry/browser';
import { BACKEND_URL, IS_PROD_ENVIRONMENT, MATTERMOST_URL, SENTRY_DSN, SENTRY_ENVIRONMENT } from './app/consts/env';

declare const VERSION: string;

const ignoreErrors = [
  // Virtuoso's resize observer can this error, which is caught by DnD and aborts dragging.
  'ResizeObserver loop limit exceeded',
  'ResizeObserver loop completed with undelivered notifications',
];

const tracing = [BACKEND_URL, MATTERMOST_URL];

function initSentry() {
  Sentry.init({
    enabled: process.env.NODE_ENV === 'production',
    environment: SENTRY_ENVIRONMENT,
    dsn: SENTRY_DSN,
    release: VERSION,
    tracePropagationTargets: tracing,
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 1.0,
    replaysOnErrorSampleRate: 1.0,
    beforeSend(event, hint) {
      const message = (hint?.originalException as Error)?.message || event.message || '';

      if (ignoreErrors.some((error) => error.includes(message))) {
        return null;
      }

      return event;
    },
  });

  if (IS_PROD_ENVIRONMENT) {
    Sentry.addIntegration(
      Sentry.replayIntegration({
        maskAllText: false,
        maskAllInputs: false,
        blockAllMedia: false,
      })
    );
  }

  Sentry.addIntegration(Sentry.browserTracingIntegration());
}

const client = Sentry;

export { initSentry, client };
